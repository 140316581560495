import { Textarea } from "theme-ui"

const theme = {
  colors: {
    text: "#1c1c1c",
    background: "#ffffff",
    lightBackground: "#FAFAFC",
    grayBorder: "#979797",
    primary: "#0098D7",
    lightPrimary: "RGB(0, 152, 215,.2)",
    secondary: "#0098D7",
    light: "#ffffff",
    dark: "#1c1c1c",
    lightGrey: "#999999",
    menuSidebarBackground: "#E4E4E4",
  },
  fonts: {
    body: '"Lato", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    heading:
      '"Lato", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
    roc: '"roc-grotesk", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
  },
  breakpoints: ["40em", "64em", "90em"],
  space: [0, 4, 8, 16, 20, 32, 48, 64, 96, 128, 192, 256, 512],
  fontSizes: [12, 15, 18, 21, 24, 32, 48, 60, 96, 120, 150],
  fontWeights: {
    body: 300,
    heading: 400,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  // sizes: {
  //   container: 1440,
  // },
  radii: {
    none: "0",
    xs: ".25rem",
    sm: ".5rem",
    md: "1rem",
    lg: "2rem",
    full: "9999px",
  },
  shadows: {
    none: "none",
    default:
      "0 20px 40px -10px rgba(50,50,93,0.15),0 10px 30px -20px rgba(0,0,0,0.15)",
  },
  forms: {
    label: {
      fontSize: [2, 2, 2],
      mb: [1, 2, 2],
    },
    input: {
      fontSize: [3, 3, 4, 4],
      fontFamily: "roc",
      borderRadius: 0,
      color: "light",
      border: "none",
      borderBottom: "1px solid",
      borderColor: "light",
      "&::placeholder": {
        color: "light",
        opacity: 0.6,
      },
    },
    textarea: {
      fontSize: [3, 3, 4, 4],
      fontFamily: "roc",
      borderRadius: 0,
      color: "light",
      border: "none",
      borderBottom: "1px solid",
      borderColor: "light",
      "&::placeholder": {
        color: "light",
        opacity: 0.6,
      },
    },
  },
  text: {
    default: {
      lineHeight: "body",
      fontSize: [2],
    },
    heading: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
    },
    h1: {
      fontSize: [6, 7],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
    },
    h2: {
      fontSize: [5, 6],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h3: {
      fontSize: [5],
      fontFamily: "heading",
      lineHeight: "heading",
      fontWeight: "heading",
      color: "primary",
      mb: 3,
      mt: 4,
    },
    h4: {
      fontSize: 4,
      fontWeight: "bold",
      color: "primary",
      mb: 2,
      mt: 4,
    },
    h5: {
      fontSize: 4,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },
    h6: {
      fontSize: 3,
      color: "dark",
      fontWeight: "body",
      lineHeight: "heading",
      mb: 2,
      mt: 4,
    },

    caption: {
      fontSize: 0,
      color: "lightGrey",
      fontWeight: "bold",
    },
    article: {
      fontSize: 2,
      lineHeight: 1.2,
    },

    filterTitle: {
      opacity: 1,
      color: "dark",
      fontFamily: "body",
      fontSize: "18px",
      fontWeight: 700,
      fontStyle: "normal",
      letterSpacing: "0px",
      textAlign: "left",
      lineHeight: "40px",
    },
  },
  layout: {
    container: {
      // maxWidth:"1731px", TODO
      padding: [3, 4],
    },
    sm: {
      maxWidth: "720px",
      padding: [3, 4],
    },
    md: {
      maxWidth: "1020px",
      padding: [3, 4],
    },
    fw: {
      maxWidth: "100%",
      padding: [3, 4],
    },
    header: {
      maxWidth: "100%",
    },
  },
  badge: {
    px: ["9px"],
    pt: ["7px"],
    pb: ["5px"],
    m: [0],
    border: "1px solid",
    borderColor: "lightPrimary",
    backgroundColor: "lightPrimary",
    borderRadius: "full",
    textTransform: "uppercase",
    textDecoration: "none",
    color: "primary",
    lineHeight: 1,
    fontSize: [1],
    fontWeight: "400",
    fontFamily: "roc",
    transition: "all .2s ease",
    "&:hover": {
      color: "dark",
      borderColor: "primary",
    },
  },
  buttons: {
    primary: {
      cursor: "pointer",
      color: "light",
      bg: "primary",
      "&:hover": {
        bg: "dark",
      },
    },
    secondary: {
      borderRadius: 0,
      paddingX: [3, 4],
      paddingY: [2, 3, 3],
      fontFamily: "roc",
      fontSize: [3, 3, 4],
      color: "primary",
      backgroundColor: "light",
    },
    success: {
      borderRadius: 0,
      paddingX: [3, 4],
      paddingY: [2, 3, 3],
      fontFamily: "roc",
      fontSize: [3, 3, 4],
      color: "light",
      backgroundColor: "dark",
    },
  },
  links: {
    nav: {
      paddingX: 3,
      paddingY: 3,
      backgroundColor: "primary",
      "&.active": {
        color: "primary",
      },
    },
    tab: {
      textDecoration: "none",
      mr: 3,
      color: "text",
      "&.active": {
        color: "primary",
        fontWeight: "bold",
      },
    },
  },
  styles: {
    root: {
      fontFamily: "body",
      fontWeight: "body",
      a: {
        // fontSize: [1],
        transition: "all .2s ease",
        color: "primary",
        "&:hover": {
          textDecoration: "none",
        },
      },
      "--swiper-theme-color": "#00a889",
      // ".swiper-container": { pb: 5 },
      "::placeholder": {
        color: "#000000",
        opacity: "0.5",
      },
    },
  },
}

export default theme
