import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import supabase from "../services/supabase"
import { useAlert } from "react-alert"

export const UserContext = React.createContext()
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null)
  const alert = useAlert()
  useEffect(() => {
    supabase.auth.onAuthStateChange((event, session) => {
      console.log(event, session)
      if (session) {
        setUser(session.user)
      } else {
        setUser(null)
      }
      // if (event === "INITIAL_SESSION" && session) {
      //   alert.show("Welcome back!", {
      //     type: "success",
      //   })
      // }
      if (event === "SIGNED_IN") {
        alert.show("Welcome back!", {
          type: "success",
        })
      }
      if (event === "USER_UPDATED") {
        navigate("/", {
          replace: true,
        })
      }
    })
  }, [])
  return (
    <UserContext.Provider value={{ user }}>{children}</UserContext.Provider>
  )
}
